import React, { useState, useEffect } from 'react'
import { Layout, Table, Button, Image, Spin, Flex, Popconfirm, Tag } from 'antd'
import BreadcrumpComp from '../../component/breadcrumb'
import { APIHandler } from '../../handler/apiHandler'
import { SetPageTitle } from '../../handler/commonHandler'
import moment from 'moment'

const Refunds = ({ messageApi }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [refundData, setRefundData] = useState([]) ;

  useEffect(() => {SetPageTitle("Refunds")}) ; 

  const columns = [
    {
      title: 'Customer',
      width: "20%",
      key: '0',
      dataIndex: 'customer',
      render: (text, record) => (
        <Flex
          justify='flex-start' vertical
          style={{ paddingLeft: '1rem' }}
          className='category-data-information'
        >
          <Image
            src={record?.cart_customer?.profile_img}
            className='category-image'
            height={'45px'}
            width={'45px'}
          />

          {/* Category name  */}

          <div className='category-name'>{text}</div>
        </Flex>
      ),
      align: 'center'
    },
    {
      title: 'Customer Mobile',
      key: '1',
      align: "center", 
      dataIndex: 'customer_mobile'
    },
    {
      title: 'Service',
      key: '2',
      width: "20%", 
      dataIndex: 'service',
      render: (text, record) => (
        <Flex
          justify='left'
          style={{ paddingLeft: '1rem' }}
          className='category-data-information'
        >
          <Image
            src={record?.vendor_service?.sub_category?.category_image}
            className='category-image'
            height={'45px'}
            width={'45px'}
          />

          {/* Category name  */}

          <div className='category-name'>{text}</div>
        </Flex>
      ),
      align: 'center'
    },
    {
      title: 'Amount',
      dataIndex: 'total_amount',
      fixed: 'right',
      align: "center", 
      key: '7', 
      render: (text, record) => (
        <div>${parseFloat(parseFloat(text) / 100).toFixed(2)}</div>
      )
    },
    {
      title: 'Refund Id',
      key: '6',
      width: "15%", 
      align: "center", 
      dataIndex: 'refund_id'
    },
    {
      title: 'Date',
      key: '5',
      align: "center", 
      dataIndex: 'date',
      render: (text, record) => (
        moment(text).format("MM-DD-YYYY HH:MM:SS")
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      fixed: "right", 
      render: (text, record) => (
        text === 'Cancelled'?<>
          <Tag color="green">Customer Cancel</Tag>
        </>:<>
          <Tag color="yellow">Vendor Cancel</Tag>
        </>
      ), 
      align: "center",
    },
  ]

  // **** Retervide Refund transaction information data **** // 
  const fetchRefundData = async () => {
    setIsLoading(true)

    let responseData = await APIHandler('POST', {}, 'admin/allRefunds')

    setIsLoading(false)
    if (responseData === false) {
      messageApi.warning('Network request failed')
    } else if (responseData['status'] === true) {
      setRefundData(
        responseData.data.map((element, index) => {
          const data = {
            ...element,
            id: parseInt(index) + 1,
            customer:
              element?.cart_customer?.firstname +
              ' ' +
              element?.cart_customer?.lastname,
            customer_mobile: element?.cart_customer?.mobileNumber,
            service: element?.vendor_service?.sub_category?.name,
            date: element?.booking?.refund_date,
            refund_id: element?.booking?.refund_detail?.refund_id,
            total_amount: element?.booking?.refund_detail?.amount,
            status: element?.booking?.status
          }
          return data
        })
      )
    } else {
      messageApi.warning(responseData?.message)
    }
  }

  useEffect(() => {
    fetchRefundData()
  }, [])

  return (
    <Layout
      className='admin-panel-layout'
      style={{
        maxHeight: '100vh',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: '0.5rem'
      }}
    >
      <BreadcrumpComp data={[{ name: 'Customer Refunds' }]} />
      <Spin spinning={isLoading}>
        <Table
          style={{ height: '100vh', marginTop: '0.8rem' }}
          columns={columns}
          dataSource={refundData}
          pagination = {false}
          scroll={{
            x: 1200,
            y: '85vh'
          }}
        />
      </Spin>
    </Layout>
  )
}

export default Refunds
