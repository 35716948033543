import React, {useState, useEffect, useRef} from "react";
import { Layout, Table, Breadcrumb, Button, Tooltip, Flex, Spin, notification, Image, Drawer, Input, Space } from "antd";
import { VerifiedOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import BreadcrumpComp from "../../component/breadcrumb";
import CustomerProfile from "../../component/customer/customerProfile"

import * as CitizenColorCode from "../../colorcode/colorcode";
import { APIHandler } from "../../handler/apiHandler";
import UserImage from "../../assert/Image/user-image.png" ; 
import { SetPageTitle } from "../../handler/commonHandler";
import moment from "moment";

const User = ({messageApi}) => {

  const [loading, setLoading] = useState(false) ; 

  const [userData, setUserData] = useState([]) ; 
  const [customerProfile, setCustomerProfile] = useState(false) ; 
  const [customemrProfileData, setCustomerProfileData] = useState({}) ;
  
  useEffect(() => {
    SetPageTitle("Customers") ;
  }, []) ; 

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className="search-filter-option-button"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-option-button"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-close-button"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  // Customer column table action
  const TableAction = (record) => {
    return (

      <Tooltip placement="bottom" title="Customer information">
        
        <Button
          type="primary"
          shape="round"
          icon={
            <VerifiedOutlined
              style={{ color: CitizenColorCode.PrimaryColor }}
            />
          }
          onClick={() => {setCustomerProfile(true); setCustomerProfileData(record) ;  }}
          size={"small"}
          className="user-delete"
        />

      </Tooltip>
    );
  };
  
  // Customer table column 
  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      width: 80, 
      align: "center", 
      sorter: (a,b) => a.index - b.index
    },
    
    {
      title: "Profile image",
      dataIndex: "profile_img",
      key: "profile_img",
      width: 150, 
      render: (text, record) => (
        text === null?<>
          <Image className="user-profile-image" src={UserImage} alt={record.name} width={56} height={56} />
        </>:<>
          <Image className="user-profile-image" src={text} alt={record.name} width={56} height={56} />
        </>
      ),
      align: "center"
    },

    {
      title: "First name",
      dataIndex: "firstname",
      key: "firstname",
      width: 150, 
      align: "center", 
      sorter: (a,b) => a.firstname - b.firstname, 
      ...getColumnSearchProps("firstname")
    },

    {
      title: "Middle name",
      dataIndex: "middlename",
      key: "middlename",
      width: 150, 
      align: "center", 
      ...getColumnSearchProps("middlename")
    },
    
    {
      title: "Last name",
      dataIndex: "lastname",
      key: "lastname",
      width: 150, 
      align: "center", 
      ...getColumnSearchProps("lastname")
    },
    
    {
      title: "Mobile number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      width: 150, 
      align: "center", 
      sorter: (a,b) => a.mobileNumber - b.mobileNumber, 
    },
    
    {
      title: "Account create at",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150, 
      align: "center", 
      render: (text, record) => (
        moment(text).format("MM-DD-YYYY HH:MM:SS")
      ), 
      sorter: (a,b) => a.createdAt - b.createdAt
    },
    
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      render: (text, record) => <TableAction record = {record} />,
      align: "center"
    },
  ];

  // **** Fetch current user information **** // 
  const FetchUserInformation = async () => {

    setLoading(true) ; 

    let responseData = await APIHandler("GET", {}, "admin/allCustomer") ; 

    setLoading(false) ; 

    if (responseData === false){

      messageApi.warning("Network request failed") ; 

    } else if (responseData['status'] === true){

      // Assuming responseData['data'] is an array of objects
      const updatedUserData = responseData['data'].map((item, index) => ({ ...item, index: parseInt(index +1) }));

      setUserData(updatedUserData);

    
    } else {

      messageApi.warning(responseData?.message) ; 
    }
  } ; 

  useEffect(() => {
    FetchUserInformation() ; 
  }, []) ; 


  return (
    <>
      <Layout
        className="admin-panel-layout"
        style={{
          maxHeight: "100vh",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "0.5rem",
        }}
      > 

        <BreadcrumpComp
          data = {[{"name": "Customers"}]}
        />

        <Spin spinning = {loading}>
          <Table
            style={{ height: "100vh", marginTop: '0.8rem' }}
            columns={columns}
            dataSource={userData}
            pagination = {false}
            scroll={{
              x: 1200,
              y: "83vh"
            }}
          />
        </Spin>

      </Layout>

      {customerProfile && (
        <CustomerProfile
          close = {setCustomerProfile}
          open = {customerProfile}
          userInformation={customemrProfileData}
        />
        
      )}
    </>
  );
};

export default User;
