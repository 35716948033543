import React from "react";
import NotFoundImage from "../../assert/Image/not-found.jpg" 
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "./notfound.scss" ; 

const NotFound = () => {

    const navigate = useNavigate() ; 

    return(
        <div style={{height:"100vh", width: "100vw", overflowY: "hidden", overflowX:"hidden"}}>

            <div className="back-button-option-layout">
                    
                {/* ==== Back button option image ====  */}
                <Button type="primary" className="back-button"
                    onClick={() => {navigate("/")}}
                    >
                    Back
                </Button>
            
            </div>

            {/* Not found image  */}
            <div className="not-found-image-layout">
                <img 
                    src={NotFoundImage} 
                    className="not-found-image" alt="" srcset="" 
                />
            </div>

        </div>
    )
}

export default NotFound ; 