import React, { useEffect } from "react";
import { Drawer, Table } from "antd";
import { useState } from "react";
import dateFormat from "dateformat";

const CustomerProfile = ({open, close, userInformation}) => {

    const coulmns = [
        {
            title: "Index", 
            dataIndex: "index", 
            width: 10
        }, 

        {
            title: "Key", 
            dataIndex: "key", 
            width: 10
        }, 

        {
            title: "Value", 
            dataIndex: "value", 
            width: 60
        }
    ]


    const [tableData, setTableData] = useState([]) ; 

    useEffect(() => {

        setTableData([
            {
                key: "First name", 
                value : userInformation?.record?.firstname, 
                index: 1
            }, 

            {
                key: "Middle name", 
                value: userInformation?.record?.middlename, 
                index: 2
            }, 

            {
                key: "Last name", 
                value: userInformation?.record?.lastname, 
                index: 3
            }, 

            {
                key: "DOB", 
                value: userInformation?.record?.dob, 
                index: 4
            }, 

            {
                key: "Mobile number", 
                value: userInformation?.record?.mobileNumber, 
                index: 5
            }, 

            {
                key: "Email address", 
                value: userInformation?.record?.email, 
                index: 6
            }, 
            
            {
                key: "Email verification", 
                value: userInformation?.record?.emailVerified, 
                index: 7
            },

            {
                key: "Account created at", 
                value: dateFormat(userInformation?.record?.createdAt, "default"), 
                index: 8
            },

            {
                key: "Last updated at", 
                value: dateFormat(userInformation?.record?.updatedAt, "default"), 
                index: 9
            }, 


        ])
    }, []) ; 

    return(
        <Drawer
            title = "Customer Profile"
            placement = "right"
            closable = {true}
            open = {open}
            width={700}
            onClose={() => {close(false) ; }}
        >   

            <Table
                columns={coulmns}
                dataSource={tableData}
            />


        </Drawer>
    )
}

export default CustomerProfile ; 