import AWS from 'aws-sdk' ; 
import {v4 as uuid4} from 'uuid' ; 

const UploadImageHandler = async (imageFile) => { 

    const cloudurl = "https://cetizencare-verification-image.s3.us-east-2.amazonaws.com/" ; 
    const bucket_name = "cetizencare-verification-image"; 
    const region = "us-east-2" ; 

    AWS.config.update({
        accessKeyId: "AKIAW2WWIDT7XI3P5NEX",
        secretAccessKey: "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",
    });

    // Bucket configuration

    const s3 = new AWS.S3({
        params: { Bucket: bucket_name },
        region: region,
    });

    // Create File name 
    const fileName = imageFile?.file?.name;
    const fileExtension = fileName ? fileName.split('.').pop() : null;
    const UploadFileName = `${uuid4()}.${fileExtension}` ; 

    // Files paameter

    const params = {
        Bucket : bucket_name, 
        Key : UploadFileName, 
        Body: imageFile?.file?.originFileObj
    }; 

    try {
        await s3.upload(params).promise();
        return `${cloudurl}${UploadFileName}`;
      } catch (error) {
        console.error("Error uploading file:", error);
        return null; // Return null or handle the error accordingly
      }
}

export default UploadImageHandler ; 