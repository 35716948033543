import { Modal, Form, ColorPicker, Spin, Upload, notification, Input, Button, message, DatePicker, Row, Col } from "antd";
import UploadImageHandler from "../../handler/imageUpload";
import { UploadOutlined } from "@ant-design/icons";
import { APIHandler } from "../../handler/apiHandler";
import { useEffect, useState } from "react";
import dayjs from 'dayjs'
import "./createCouponComp.scss" ; 

const {RangePicker} = DatePicker ; 

const CreateCouponComp = ({
    isModalOpen, 
    setModalOpen, 
    messageApi,
    setRefetch,
    isEdit,
    setIsEdit,
    initialValue,
    fileList,
}) => {

    const [confirmLoading, setConfirmLoading] = useState(false) ; 
    const [frominitialValue, setFromInitialValue] = useState({}) ; 
    const [createCoupnForm] = Form.useForm() ;

    const handleSubmit = async (values) => {
        if(!isEdit){
            setConfirmLoading(true) ; 

        let S3UploadURL 
        if(values?.couponImage)
            S3UploadURL = await UploadImageHandler(values?.couponImage);
        else S3UploadURL=''
        
        let requestPayload = {
            "offer_name": values?.couponName, 
            "offer_type": values?.offerType, 
            "offer_code": values?.offerCode, 
            "description": values?.offerdescription, 
            "image": S3UploadURL, 
            "percentage": values?.offerpercentage, 
            "maxAmount":  Number(values?.offermaxAmount), 
            "maxDiscount": values?.offermaxdiscount, 
            "start_date": dayjs(values?.Offerdate[0]), 
            "end_date": dayjs(values?.Offerdate[1])
        } ; 

        let responseData = await APIHandler("POST", requestPayload, "admin/coupons/add") ; 

        setConfirmLoading(false) ; 

        if (responseData === false){
            messageApi.warning("Network request failed"); 
        } else if (responseData?.status === true){
            messageApi.success("Create coupon successfully");
            setRefetch(true) 
            setModalOpen(false)
        }   else {
            messageApi.warning(responseData?.message) ; 
        }
    }else {
        setConfirmLoading(true)
        let S3UploadURL=''
        // if (values?.couponImage)
        //     S3UploadURL = await UploadImageHandler(values?.couponImage)
        // else S3UploadURL = ''


        let requestPayload = {
        offer_name: values?.couponName,
        offer_type: values?.offerType,
        offer_code: values?.offerCode,
        description: values?.offerdescription,
        image: S3UploadURL,
        percentage: values?.offerpercentage,
        maxAmount: Number(values?.offermaxAmount),
        maxDiscount: values?.offermaxdiscount,
        start_date: dayjs(values?.Offerdate[0]),
        end_date: dayjs(values?.Offerdate[1])
        }

        let responseData = await APIHandler('PATCH', requestPayload, `admin/coupons/edit/${initialValue?.id}`)

        setConfirmLoading(false)

        if (responseData === false) {
            messageApi.warning('Network request failed')
        } else if (responseData?.status === true) {
            messageApi.success('Update coupon successfully')
            setRefetch(true)
            setModalOpen(false)
            setIsEdit(false)
        } else {
            messageApi.warning(responseData?.message)
        }
        }
    }

    const props = {
        action: "//jsonplaceholder.typicode.com/posts/",
        listType: "picture",
        defaultFileList : [...fileList]
    };


    return(
        <>
            <Modal
                title = "Create coupon"
                open = {isModalOpen}
                centered
                onCancel={() => {setModalOpen(false) ; }}
                className="citizencare-model citizencare-coupon-model"
                onOk={() => createCoupnForm.submit()}
                >
                <Spin spinning = {confirmLoading}>
                    
                    <Form
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        form={createCoupnForm}
                        initialValues = {initialValue}
                        onFinish={handleSubmit}
                        autoComplete={"off"}
                        className="category-create-layout"
                    >
                        
                        {/* ==== Coupon name input ====  */}

                        <Form.Item  
                            name="couponName" // Adjust the name to a single word without spaces
                            label="Coupon name"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: "Please, Enter Coupon name",
                                },
                            ]}
                        >
                            <Input
                                style={{
                                    // marginBottom: "0.1rem",
                                    padding: "0.7rem",
                                    fontSize: "1rem",
                                }}
                                placeholder="Please, Enter Coupon name"
                            />
                            
                        </Form.Item>
                        
                        {/* ==== Offer name ====  */}
            
                        <Form.Item
                            name='offerType'
                            label='Offer type'
                            rules={[
                                {
                                required: true,
                                whitespace: true,
                                message: 'Please, Enter Offer type'
                                }
                            ]}
                            >
                            <Input
                                style={{
                                // marginBottom: '0.1rem',
                                padding: '0.7rem',
                                fontSize: '1rem'
                                }}
                                placeholder='Please, Enter Offer type'
                            />
                        </Form.Item>

                        <Row gutter={[16,0]}>
                            {' '}

                            <Col span={12}>

                                {/* Offer description  */}

                                <Form.Item
                                    name="offerdescription" 
                                    label="Offer description"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please, Enter Offer description",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            // marginBottom: "0.1rem",
                                            padding: "0.7rem",
                                            fontSize: "1rem",
                                        }}
                                        placeholder="Please, Enter Offer description"
                                    />

                                </Form.Item>
                                
                            </Col>

                            <Col span={12}>

                                {/* Offer code  */}
                                
                                <Form.Item
                                    name="offerCode" // Adjust the name to a single word without spaces
                                    label="Coupon code"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please, Enter Offer code",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            // marginBottom: "0.1rem",
                                            padding: "0.7rem",
                                            fontSize: "1rem",
                                        }}
                                        placeholder="Please, Enter Offer code"
                                    />

                                </Form.Item>

                            </Col>
                        </Row>


                        {/* Offer percentage  */}
                        <Row gutter={[16, 0]}>
                        {' '}
                            <Col span={12}>
                                <Form.Item
                                    name="offerpercentage" // Adjust the name to a single word without spaces
                                    label="Offer percentage"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please, Enter Offer percentage",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            // marginBottom: "0.1rem",
                                            padding: "0.7rem",
                                            fontSize: "1rem",
                                        }}
                                        placeholder="Please, Enter Offer percentage"
                                    />

                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                {/* Offer start date  */}
                                
                                <Form.Item
                                    name="Offerdate" // Adjust the name to a single word without spaces
                                    label="Offer date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please, Select Offer date",
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        style={{
                                            // marginBottom: "0.1rem",
                                            padding: "0.8rem",
                                            fontSize: "1rem",
                                        }}
                                        format={"YYYY-MM-DD"}
                                    />
                                    
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16,0]}>
                            {''}
                            <Col span={12}>
                                {/* Offer maxamout  */}

                                <Form.Item
                                    name="offermaxAmount" // Adjust the name to a single word without spaces
                                    label="Offer MaxAmount"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please, Enter Offer Maxamount",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            // marginBottom: "0.1rem",
                                            padding: "0.7rem",
                                            fontSize: "1rem",
                                        }}
                                        placeholder="Please, Enter Offer Maxamount"
                                    />

                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                
                                {/* Offer maxdiscount */}

                                <Form.Item
                                    name="offermaxdiscount" 
                                    label="Offer MaxDiscount"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: "Please, Enter Offer Maxdiscount",
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{
                                            // marginBottom: "0.1rem",
                                            padding: "0.7rem",
                                            fontSize: "1rem",
                                        }}
                                        placeholder="Please, Enter Offer Maxdiscount"
                                    />

                                </Form.Item>
                            </Col>
                        </Row>



                        
                        {/* Coupon image  */}

                        <Form.Item
                                name="couponImage"
                                label="Coupon Image"
                                style={{ marginTop: "14px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select coupon image",
                                    },
                                ]}
                            >
                            <Upload {...props} >
                                <Button icon={<UploadOutlined />}>Coupon image</Button>
                            </Upload>    
                        </Form.Item>


                    </Form>
                </Spin>
            </Modal>
        </>
    )
}

export default CreateCouponComp ; 