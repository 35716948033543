import React, { useEffect, useState, useRef } from "react";
import {
  Layout,
  Table,
  Button,
  Tooltip,
  notification,
  Flex,
  Image,
  Spin, 
  Input, 
  Space,
} from "antd";
import {
  EditOutlined,
  InfoCircleOutlined,
  UploadOutlined,
  SearchOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import * as CitizenColorCode from "../../colorcode/colorcode"; 
import { SetPageTitle } from "../../handler/commonHandler";

import { APIHandler } from "../../handler/apiHandler";
import BreadcrumpComp from "../../component/breadcrumb";
import CreateCategoryComp from "../../component/category/createCategory";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";

const Category = ({messageApi}) => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Update category data
  const [categoryId, setCategoryId] = useState(null);
  const [editModal, setEditModal] = useState(false) ; 

  const [categoryData, setCategoryData] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className="search-filter-option-button"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-option-button"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-close-button"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    SetPageTitle("Category") ;
  }, []) ; 
  
  const TableAction = (record) => {

    return (
      <Flex style={{justifyContent: "center"}}>
        {/* ==== View all subcategories option ====  */}

        <Tooltip
          placement="bottom"
          title="View all Subcategories"
          className="category-action-option"
        >
          <Button
            type="primary"
            shape="round"
            onClick={() => { navigate(`/category/${record?.record?.id}`)}}
            icon={
              <InfoCircleOutlined
                style={{ color: CitizenColorCode.PrimaryColor }}
              />
            }
            size={"small"}
            className="user-delete"
          />
        </Tooltip>

        {/* ==== Edit category option icon ====  */}

        <Tooltip
          placement="bottom"
          title="Edit category"
          className="category-action-option"
        >
          <Button
            type="primary"
            shape="round"
            icon={
              <EditOutlined style={{ color: CitizenColorCode.PrimaryColor }} />
            }
            onClick={() => { 
              setCategoryId(record.record?.id); 
              setIsModalOpen(true); 
              setEditModal(true) ; 
            }}
            size={"small"}
            className="user-delete"
          />
        </Tooltip>

      </Flex>
    );
  };

  const columns = [
    {
      title: "Index",
      width: 10,
      dataIndex: "key",
      align: "center", 
      sorter: (a,b) => a.key - b.key, 
    },
    
    {
      title: "Name",
      width: 30,
      dataIndex: "name",
      ...getColumnSearchProps("name"), 
      render: (text, record) => (
        <Flex style={{paddingLeft : "1rem"}} className="category-data-information">

          <Image
            src={
              record?.category_image
            }
            className="category-image"
            height={"45px"}
            width={"45px"}
          />

          {/* Category name  */}

          <div className="category-name">{text}</div>
        </Flex>
      ),
      align: "center", 
      sorter: (a, b) => a.name - b.name

    },

    {
      title: "Created at", 
      width: 20 ,
      dataIndex: 'createdAt', 
      render: (text, record) => dateFormat(record?.createdAt, "default"), 
      align: "center", 
      sorter: (a,b) => a.createdAt - b.createdAt
    }, 

    {
      title: "Update at", 
      width: 20 ,
      dataIndex: 'updatedAt', 
      render: (text, record) => dateFormat(record?.updatedAt, "default"), 
      align: "center", 
      sorter: (a, b) => a.updatedAt - b.updatedAt 
    }, 

    {
      title: "Action",
      key: "operation",
      align : "center", 
      fixed: "right",
      width: 30,
      render: (text, record) => <TableAction record={record} />,
    },
  ];

  // **** Fetch all category information **** // 
  const FetchAllCategory = async () => {

    setIsLoading(true);

    let responseData = await APIHandler("GET", {}, "admin/getAllCategories");

    setIsLoading(false);

    if (responseData === false) {

      messageApi.warning("Network request failed") ; 

    } else if (responseData["status"] === true) {

      setCategoryData(responseData.data.map((element, index) => ({
        ...element,
        key: parseInt(index) + 1,
        name: element.name,
        category_image: element?.category_img,
      })));

    } else {
      
      messageApi.warning(responseData?.message) ; 
    }
  };

  useEffect(() => {
    FetchAllCategory();
  }, []);

  return (
    <>
      <Layout
        className="admin-panel-layout"
        style={{
          maxHeight: "100vh",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "0.5rem",
        }}
      >

        {/* Breadcumb information  */}
        <BreadcrumpComp data={
          [ {"name": "Category"}]} />

        <div className="table-data" style={{overflow: "hidden"}}>

          <div className="table-option">
            
            {/* Create new category option button  */}
            <Button
              type="primary"
              className="create-category-button"
              onClick={() => {
                setIsModalOpen(true);
                setEditModal(false) ; 
              }}
            >
              Create new category
            </Button>
          
          </div>
            
          <Spin spinning = {isLoading}>
            <Table columns={columns} dataSource={categoryData}  />
          </Spin>

        </div>
      </Layout>

      {/* ==== Create category model ====  */}
      
      {isModalOpen && (
        <CreateCategoryComp
          isModalOpen = {isModalOpen}
          setIsModalOpen = {setIsModalOpen}
          FetchAllCategory = {FetchAllCategory}
          editModal = {editModal}
          categoryId = {categoryId}
          messageApi = {messageApi}
        />

      )}

    </>
  );
};

export default Category;
