import React from "react";
import User from "../pages/user/index" ; 
import Category from "../pages/category/index";
import SubCategory from "../pages/category/subcategory";
import VendorUser from "../pages/user/vendor";
import PendingVendor from "../pages/user/pendingVendor";
import Coupon from "../pages/Coupon";
import Payment from "../pages/payment";
import Transaction from "../pages/transaction";
import Refunds from "../pages/refund";
import Notification from "../pages/Notification";

const routes = [
    { path: "/category", component: <Category/>}, 
    { path: "/category/:categoryid", component: <SubCategory/>}, 
    { path: "/customers", component: <User/>}, 
    { path: "/vendor", component: <VendorUser/>}, 
    { path: "/pendingVendor", component: <PendingVendor/>}, 
    { path: "/pendingPay", component: <Payment/>}, 
    { path: "/coupon", component: <Coupon/>}, 
    { path: "/notification", component: <Notification/>},
    { path: "/transaction", component: <Transaction/>},
    { path: "/refunds", component: <Refunds/>}
]


export  {routes} ; 