import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Spin,
  message
} from "antd";
import React, { useEffect, useState } from "react";
import { APIHandler } from "../../handler/apiHandler";
import { useNavigate } from "react-router-dom";
import "./index.scss" ; 

const Login = () => {

  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage() ;  

  const [loginForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false) ;
  
  // **** Request admin details verification handler **** // 
  const AdminDetailsVerification = async () => {

    let accessToken = localStorage.getItem("accessToken") ; 

    if (accessToken !== null){

      let responseData = await APIHandler("POST", {}, "admin/adminDetail") ; 
  
      if (responseData === false){

        messageApi.warning("Failed to verify admin details") ; 
      
      } else if (responseData?.status){
        navigate("/category") ; 
      
      }
    }


  }

  useEffect(() => {
    AdminDetailsVerification() ; 
  }, []) ;

  // **** Login Request **** // 
  const handleSubmit = async (values) => {

    setIsLoading(true);

    let requestPayload = {
      "username": values?.username,
      "password": values?.password
    };

    let responseData = await APIHandler("POST", requestPayload, "admin/login");

    setIsLoading(false);

    if (responseData === false) {

      messageApi.warning("Network request failed") ; 

    } else if (responseData['status'] === true) {

      messageApi.success("Login successfully") ;  

      localStorage.setItem("accessToken", responseData['data']?.access_token);
      navigate("/category");

    } else {

      messageApi.warning(responseData?.message) ; 

    }

  };
  

  return (
    <>
      {contextHolder}
      <div className="login-page-wrapper">
        <Row>

          <Col xs={24} md={24} lg={24} className="login-card-wrapper">

            <Card className="login-card" bordered={false}>

              <Spin spinning={isLoading}>

                {/* ==== Admin login option title ====  */}

                <Row className="card-header" style={{ margin: 0 }}>
                  <h2 className="login-from-header">
                    CetizenCare
                  </h2>
                </Row>

                {/* ==== Admin login option from ====  */}

                <Form
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  form={loginForm}
                  onFinish={handleSubmit}
                  autoComplete={"off"}
                >
                  <Form.Item
                    name="username"
                    label="Username"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter username",
                      },
                    ]}
                  >
                    <Input
                      style={{ marginBottom: "0.5rem", padding: "0.7rem", fontSize: "1rem" }}
                      placeholder="Enter Username"
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    preserve={false}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter password",
                      },
                    ]}
                  >
                    <Input.Password
                      name="password"
                      autoComplete={"off"}
                      style={{ marginBottom: "0.5rem", padding: "0.7rem", fontSize: "1rem" }}
                      type="password"
                      placeholder="Enter Password"
                    />
                  </Form.Item>

                  <Form.Item className="m-0" >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      style={{ marginTop: "2rem" }}
                    >
                      Login
                    </Button>

                  </Form.Item>

                </Form>

              </Spin>

            </Card>

          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;
