import React, { useEffect, useState, useRef } from "react";
import { Layout, Table, Breadcrumb, Button, Tooltip, Flex, Spin, Image, Tag, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import BreadcrumpComp from "../../component/breadcrumb";
import { APIHandler } from "../../handler/apiHandler";
import dateFormat from "dateformat";
import { SetPageTitle } from "../../handler/commonHandler";
import moment from "moment";

const VendorUser = ({messageApi}) => {

  const [loading, setLoading] = useState(false) ; 
  const [vendorData, setVendorData] = useState([]) ; 

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    SetPageTitle("Vendors") ; 
  }, [ ]) ; 

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className="search-filter-option-button"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-option-button"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-close-button"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [

    {
      title: "User image", 
      dataIndex: "profile_img", 
      render: (text, record) => (
        <Image className="user-profile-image" src={text} alt={record.name} width={56} height={56} />
      ), 
      align: "center"
    }, 
  
    {
      title: "VendorId",
      dataIndex: "vendorId",
      key: "name",
      align: "center", 
      sorter: (a,b) => a.vendorId - b.vendorId, 
      ...getColumnSearchProps("vendorId")
    },

    {
      title: "Full Name",
      dataIndex: "firstname",
      key: "name",
      align: "center", 
      sorter: (a, b) => a.firstname - b.firstname, 
      ...getColumnSearchProps("firstname")
    },
  
    {
      title: "Middle name",
      dataIndex: "middlename", 
      align: "center", 
      ...getColumnSearchProps("middlename")
    },
  
    {
      title: "Last name",
      dataIndex: "lastname",
      align:"center", 
      ...getColumnSearchProps("lastname")
    },

    {
      title: "DOB",
      dataIndex: "dob",
      align:"center", 
      sorter: (a,b) => a.dob - b.dob, 
    },

    {
      title: "Mobile number",
      dataIndex: "mobileNumber",
      align: "center", 
      sorter: (a,b) => a.mobileNumber - b.mobileNumber, 
    },

    {
      title: "Email address",
      dataIndex: "email",
      align: "center", 
      ...getColumnSearchProps("email")
    },

    {
      title: "Address",
      dataIndex: "address1",
      align: "center"
    },

    
    {
      title: "Created at",
      dataIndex: "createdAt",
      render: (text, record) => (
        moment(text).format("MM-DD-YYYY HH:MM:SS")  
      ), 
      align: "center", 
      sorter : (a, b) => a.createdAt - b.createdAt
    },
    
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      render: (text, record) => (
        moment(text).format("MM-DD-YYYY HH:MM:SS")  
      ), 
      align: "center", 
      sorter : (a,b) => a.updatedAt - b.updatedAt
    },
      
    {
      title: "Proof Verified",
      dataIndex: "proofRefVerified",
      fixed: "right", 
      render: (text, record) => (
        text === true?<>
          <Tag color="green">Verified</Tag>
        </>:<>
          <Tag color="red">Not Verified</Tag>
        </>
      ), 
      align: "center"
    },

  ];

  const FetchVendorData = async () => {

    setLoading(true) ;
    
    let responseData = await APIHandler("GET", {}, "admin/allVendor") ; 

    setLoading(false) ; 
    
    if (responseData === false){

      messageApi.warning("Network request failed") ; 
    
    } else if (responseData?.status === true){

      setVendorData([...responseData?.data]) ; 
    } else{
      messageApi.warning(responseData?.message) ; 
    }
  }

  useEffect(() => {
    FetchVendorData() 
  }, []) ; 

  return (
    <>
      <Layout
        className="admin-panel-layout"
        style={{
          maxHeight: "100vh",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "0.5rem",
        }}
      > 

        <BreadcrumpComp
          data = {[
            {"name": "Vendors"}
          ]}
        />

        <Spin spinning = {loading}>

          <Table
            style={{ height: "190vh", marginTop: '0.8rem' }}
            columns={columns}
            dataSource={vendorData}
            pagination = {false}
            scroll={{
              x: 1500,
              y: "85vh",
            }}
          />

        </Spin>

      </Layout>
    </>
  );
};

export default VendorUser;
