import React, { useState, useEffect, useRef } from "react";
import { Layout, Button, Image, Flex, Tooltip, Table, Modal, Spin, Form, Upload, Input, Space } from "antd";
import { EditOutlined, UploadOutlined, SearchOutlined } from "@ant-design/icons";
import BreadcrumpComp from "../../component/breadcrumb"; 
import Highlighter from "react-highlight-words";
import { useParams } from "react-router-dom";
import { APIHandler } from "../../handler/apiHandler";
import * as CitizenColorCode from "../../colorcode/colorcode";
import dateFormat from "dateformat"; 
import CreateSubCategoryComp from "../../component/category/createSubCategory";
import { SetPageTitle } from "../../handler/commonHandler";

const SubCategory = ({messageApi}) => {

  const {categoryid} = useParams() ; 

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false) ; 
  const [categoryData, setCategoryData] = useState([]);
  const [editOption, setEditOption] = useState(false) ; 

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className="search-filter-option-button"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-option-button"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-close-button"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    SetPageTitle("SubCategory") ; 
  }, []) ; 

  const TableAction = (record) => {

    return (
      <Flex>
  
        {/* ==== Edit category option icon ====  */}
  
        <Tooltip
          placement="bottom"
          title="Edit category"
          className="category-action-option"
        >
          <Button
            type="primary"
            shape="round"
            icon={
              <EditOutlined style={{ color: CitizenColorCode.PrimaryColor }} />
            }
            onClick={() => { 
              setIsModalOpen(true); 
              setEditOption(true) ; 
            }}
            
            size={"small"}
            className="user-delete"
          />
        </Tooltip>
      </Flex>
    );
  };
  
  const columns = [
    {
      title: "Index",
      width: 30,
      dataIndex: "key",
      align: "center", 
      sorter: (a,b) => a.key - b.key
    },

    {
      title: "Name",
      width: 30,
      dataIndex: "name",
      ...getColumnSearchProps("name"), 
      render: (text, record) => (
        <Flex style={{paddingLeft: "1rem"}} className="category-data-information">
          {/* Category image  */}
  
          <Image
            src={
              record?.category_image
            }
            className="category-image"
            height={"45px"}
            width={"45px"}
          />
  
          {/* Category name  */}
  
          <div className="category-name">{text}</div>
        </Flex>
      ),
      sorter: (a, b) => a.name - b.name, 
    },

    {
      title: "Created at", 
      width: 30, 
      dataIndex: "createdAt", 
      render: (text, record) => dateFormat(record?.createdAt, "default"), 
      align: "center", 
      sorter: (a,b) => a.createdAt - b.createdAt
    }, 

    {
      title: "Updated at", 
      width: 30, 
      dataIndex: "updatedAt", 
      render: (text, record) => dateFormat(record?.createdAt, "default"), 
      align: "center", 
      sorter: (a,b) => a.updatedAt - b.updatedAt
    }, 
  
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 30,
      render: (text, record) => <TableAction record={record} />,
      align: 'center'
    },
  ];

  // Fetch sub category by category id 

  const FetchParticularSubCategory = async () => {

    setIsLoading(true) ; 

    let responseData = await APIHandler("GET", {}, `admin/getAllSubCategoryByCategory/${categoryid}`) ; 

    setIsLoading(false) ; 

    if (responseData === false){

      messageApi.warning("Network request failed") ; 
    
    } else if (responseData['status'] === true){

      setCategoryData(responseData.data.map((element, index) => ({
        ...element, 
        key: parseInt(index) + 1,
        name: element.name,
        category_image: element?.category_image, 
      })));

    } else {

      messageApi.warning(responseData?.message) ; 
    }
  };

  useEffect(() => {
    FetchParticularSubCategory() ; 
  }, [])


  return (
    <>
      <Layout
        style={{
          maxHeight: "100vh",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          paddingTop: "0.5rem",
        }}
      >
        <BreadcrumpComp data={
          [
            {"name" : "Category", "href": "/category"}, 
            {"name" : "Subcategory"}
          ]
        } />

        <div className="table-data">
          
          {/* ==== Create sub category option ====  */}

          <div className="table-option">
            <Button
              type="primary"
              className="create-category-button"
              onClick={() => {
                setIsModalOpen(true);
                setEditOption(false) ; 
              }}
            >
              Create new sub category
            </Button>
          </div>

          {/* ==== Sub category data =====  */}

          <Spin spinning = {isLoading}>
            <Table columns={columns} dataSource={categoryData}   />
          </Spin>

          {/* ==== Sub category create modal ====  */}
          
          {isModalOpen && (
            <CreateSubCategoryComp
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              categoryId={categoryid}
              fetchSubCategory={FetchParticularSubCategory}
              editModal = {editOption}
              messageApi={messageApi}
            />
          )}
          
        </div>
      </Layout>
    </>
  );
};

export default SubCategory;
