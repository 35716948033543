const APIHandler = async (method, payload, route) => {

    try {
        
        // Server request url 
        let serverURL = "https://backend.cetizencare.com/api/v1/";
        // let serverURL = "http://18.219.120.123:8080/api/v1/";
        
        let request = `${serverURL}${route}` ;  

        // Access token 
        const getAccessToken = localStorage.getItem("accessToken") ; 

        let requestOptions = {
            method: `${method}`,
            headers: {
                'Content-Type': 'application/json;charset=utf-8', 
                'Authorization': `Bearer ${getAccessToken}`
            }
        };   
        
        if (method !== "GET"){
            requestOptions['body'] = JSON.stringify(payload)
        }

        let response = await fetch(request, requestOptions) ;  

        let responseData = await response.json() ; 
        return responseData ; 

    } catch (error) {
        return false ; 
    }
}

export {APIHandler} ; 
