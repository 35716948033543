import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Table,
  Button,
  Image,
  Spin,
  Flex,
  Popconfirm, 
  Input, 
  Space
} from 'antd';
import Highlighter from "react-highlight-words";
import BreadcrumpComp from "../../component/breadcrumb";
import { APIHandler } from "../../handler/apiHandler";
import { SearchOutlined } from "@ant-design/icons";
import { SetPageTitle } from "../../handler/commonHandler";
import moment from "moment";


const Transaction = ({ messageApi }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [transactionData, setTransactionData] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    SetPageTitle("Transactions") ; 
  }, []) ; 

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            className="search-filter-option-button"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-option-button"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            className="filter-close-button"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Customer',
      width: "20%",
      key: '0',
      dataIndex: 'customer',
      ...getColumnSearchProps("customer"), 
      render: (text, record) => (
        <Flex style={{ paddingLeft: "1rem" }} justify="left" className="category-data-information">

          <Image
            src={
              record?.cart_customer?.profile_img == null ? "https://cdn-icons-png.flaticon.com/128/149/149071.png" : record?.cart_customer?.profile_img
            }
            className="category-image"
            height={"45px"}
            width={"45px"}
          />

          {/* Category name  */}

          <div className="category-name">{text}</div>
        </Flex>
      ),
      align: "center", 
      sorter : (a,b) => a.cutomer - b.customer, 
    },
    {
      title: 'Customer Mobile',
      key: '1',
      align: "center",
      width: "12%",
      dataIndex: 'customer_mobile',
    },
    {
      title: 'Service',
      key: '2',
      width: "20%",
      dataIndex: 'service',
      ...getColumnSearchProps("service"), 
      render: (text, record) => (
        <Flex justify="left" style={{ paddingLeft: "1rem" }} className="category-data-information">

          <Image
            src={
              record?.vendor_service?.sub_category?.category_image == null ? "https://cdn-icons-png.flaticon.com/128/149/149071.png" : record?.vendor_service?.sub_category?.category_image
            }
            className="category-image"
            height={"45px"}
            width={"45px"}
          />

          {/* Category name  */}

          <div className="category-name">{text}</div>
        </Flex>
      ),
      align: "center", 
    },
    {
      title: 'Vendor',
      key: '3',
      width: "20%",
      dataIndex: 'vendor',
      ...getColumnSearchProps("vendor"), 
      render: (text, record) => (
        <Flex justify="left" style={{ paddingLeft: "1rem" }} className="category-data-information">

          <Image
            src={
              record?.cart_vendor?.profile_img
            }
            className="category-image"
            height={"45px"}
            width={"45px"}
          />

          {/* Category name  */}

          <div className="category-name">{text}</div>
        </Flex>
      ),
      align: "center", 
    },
    {
      title: 'Vendor Mobile',
      key: '4',
      width: "12%",
      align: "center",
      dataIndex: 'vendor_mobile', 
    },
    {
      title: 'Transaction Id',
      key: '6',
      width: "10%",
      align: "center",
      dataIndex: 'transaction_id'
    },
    {
      title: 'Amount',
      dataIndex: 'total_amount',
      width: "10%",
      align: "center",
      fixed: 'right',
      key: '7',
      ...getColumnSearchProps("total_amount"), 
    },
    {
      title: 'Date',
      key: '5',
      width: "10%",
      dataIndex: 'date',
      align: "center",
      fixed: "right",
      sorter: (a, b) => a.date - b.date, 
      render: (text, record) => (
        moment(text).format("MM-DD-YYYY HH:MM:SS")
      )
    },
  ]

  // **** Retervie transaction information **** // 
  const fetchTransactionData = async () => {
    setIsLoading(true)

    let responseData = await APIHandler('POST', {}, 'admin/allTransactions')

    setIsLoading(false)
    if (responseData === false) {
      messageApi.warning('Network request failed')
    } else if (responseData['status'] === true) {
      setTransactionData(
        responseData.data.map((element, index) => {
          const timestamp = Number(element?.booking?.payment_detail?.time) * 1000;
          let date = ''
          let formattedDate = ''
          if (!isNaN(timestamp)) {
            date = new Date(timestamp);
            formattedDate = date.toISOString()
          }

          const data = {
            ...element,
            id: parseInt(index) + 1,
            customer: element?.cart_customer?.firstname + ' ' + element?.cart_customer?.lastname,
            customer_mobile: element?.cart_customer?.mobileNumber,
            service: element?.vendor_service?.sub_category?.name,
            vendor: element?.cart_vendor?.firstname + ' ' + element?.cart_vendor?.lastname,
            vendor_mobile: element?.cart_vendor?.mobileNumber,
            date: formattedDate,
            transaction_id: element?.booking?.payment_detail?.trasaction_id,
            total_amount: parseFloat(element?.booking?.payment_detail?.amount/100).toFixed(2)
          }
          return data
        })
      )
    } else {
      messageApi.warning(responseData?.message)
    }
  }
  useEffect(() => {
    fetchTransactionData()
  }, []);

  return (
    <Layout
      className="admin-panel-layout"
      style={{
        maxHeight: '100vh',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: '0.5rem'
      }}
    >

      <BreadcrumpComp data={[{ "name": "Customer Transactions" }]} />
      <Spin spinning={isLoading}>
        <Table
          style={{ height: '100vh', marginTop: "0.8rem" }}
          columns={columns}
          pagination={false}
          dataSource={transactionData}
          scroll={{
            x: 1300,
            y: '83vh'
          }}
        />
      </Spin>

    </Layout>
  )
}


export default Transaction; 