 import React, { useEffect, useState } from 'react'
import {
  Layout,
  Table,
  Button,
  Image,
  Spin,
  Popconfirm,
  Flex,
  Tooltip, 
  Tag
} from 'antd'
import * as CitizenColorCode from '../../colorcode/colorcode'
import BreadcrumpComp from '../../component/breadcrumb'
import { APIHandler } from '../../handler/apiHandler'
import UserImage from '../../assert/Image/user-image.png'
import PaymentDetails from '../../component/payment/paymentDetails'
import { SetPageTitle } from '../../handler/commonHandler'
import { BankOutlined } from '@ant-design/icons'
import BankDetails from '../../component/payment/bankDetails'; 
import moment from 'moment'

const Payment = ({ messageApi }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [paymentData, setPaymentData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [initialData, setInitialData] = useState([])
  const [bankDetailsModal, setBankDetailsModal] = useState(false) 
  const [bankInformation, setBankInformation] = useState({}) ; 

  useEffect(() => {
    SetPageTitle("PendingPay") ;
  }, []) ; 

  // **** Pay to vendor related option handler **** // 
  const payingVendor = async id => {
    setIsLoading(true)
    let responseData = await APIHandler(
      'PATCH',
      {},
      `admin/vendorPaidByAdmin/${id}`
    )

    setIsLoading(false)
    if (responseData === false) {
      messageApi.warning('Network request failed')
    } else if (responseData['status'] === true) {
      fetchAllData()
    } else {
      messageApi.warning(responseData?.message)
    }
  }

  // **** Vendor amount click option handler **** // 
  const handleAmountClick = async (request, text) => {
    setIsModalOpen(true)
    setIsLoading(true)
    let responseData = await APIHandler(
      'GET',
      {},
      `admin/getPaymentDetails/${request?.vendor}`
    )

    setIsLoading(false)
    if (responseData === false) {
      messageApi.warning('Network request failed')
    } else if (responseData['status'] === true) {
      setInitialData(
        responseData.data.map((element, index) => ({
          ...element,
          name: element?.name,
          amount: element?.bill_json?.single_service_cost
        }))
      )
    } else {
      messageApi.warning(responseData?.message)
    }
  }

  const columns = [
    {
      title: 'Id',
      width: 50,
      key: '0',
      dataIndex: 'id'
    },
    {
      title: 'Vendor name',
      key: '1',
      dataIndex: 'name'
    },
    {
      title: 'Vendor image',
      key: '2',
      dataIndex: 'image',
      render: (text, record) =>
        text === null ? (
          <>
            <Image
              className='user-profile-image'
              src={UserImage}
              alt={record.name}
              width={56}
              height={56}
            />
          </>
        ) : (
          <>
            <Image
              className='user-profile-image'
              src={text}
              alt={record.name}
              width={56}
              height={56}
            />
          </>
        )
    },
    {
      title: 'MobileNumber',
      key: '3',
      dataIndex: 'mobile'
    },
    {
      title: 'Email',
      width: 300, 
      key: '4',
      dataIndex: 'email'
    },
    {
      title: 'Amount',
      key: '5',
      dataIndex: 'amount',
      render: (text, record) => (
        <Tag style={{cursor:"pointer"}} color='#87d068' onClick={() => handleAmountClick(record, text)}>
          Check amount
        </Tag>
      )
    },
    {
      title: 'Last Payment Date',
      key: '6',
      dataIndex: 'date', 
      render: (text, record) =>(
        moment(text).format("MM-DD-YYYY HH:MM:SS")
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      fixed: 'right',
      key: '7',
      render: (text, record) => (
        <Flex>
          {/* Bank details informatopn  */}
          <Tooltip title = "Bank details">
            <BankOutlined
              onClick={() => {setBankDetailsModal(true); setBankInformation({...record?.bank_details});}}
              style={{fontSize: "1.2rem", marginRight: "1rem", cursor: "pointer"}}
            />
          </Tooltip>

          {/* Vendor pay option  */}
          <Popconfirm
            title = "Vendor Payment"
            description = "Are you sure you want complete payment of this Vendor ?"
            okText = "Make Payment"
            onConfirm={() => payingVendor(record?.vendor)}
          >
            <Button
              style={{
                minWidth: '80px',
                height: '40px',
                border: `1px solid ${CitizenColorCode.PrimaryColor}`
              }}
            >
              {'Pay'}
            </Button>
          </Popconfirm>
        </Flex>
      )
    }
  ]

  // **** Fetch vendor paying data information **** // 
  const fetchAllData = async () => {
    setIsLoading(true)

    let responseData = await APIHandler(
      'GET',
      {},
      'admin/remainVendorPayingById'
    )

    setIsLoading(false)
    if (responseData === false) {
      messageApi.warning('Network request failed')
    } else if (responseData['status'] === true) {
      setPaymentData(
        responseData.data.map((element, index) => ({
          ...element,
          id: parseInt(index) + 1,
          name:
            element?.vendor_details?.firstname +
            ' ' +
            element?.vendor_details?.lastname,
          image: element?.vendor_details?.profile_img,
          mobile: element?.vendor_details?.mobileNumber,
          email: element?.vendor_details?.email,
          amount: element?.price ?? 0,
          date: element?.booking_detail?.admin_pay_date ?? '-', 
          bank_details: element?.bank_details[0]
        }))
      )
    } else {
      messageApi.warning(responseData?.message)
    }
  }

  useEffect(() => {
    fetchAllData()
  }, [])

  return (
    <>
      <Layout
        className='admin-panel-layout'
        style={{
          maxHeight: '100vh',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingTop: '0.5rem'
        }}
      >

        <BreadcrumpComp data={[
          {"name": "Payment"}, 
          {"name": "Pending payment"}
        ]} />

        <Spin spinning={isLoading}>
          <Table
            style={{ height: '100vh', marginTop: "0.8rem" }}
            columns={columns}
            dataSource={paymentData}
            scroll={{
              x: 1200,
              y: '75vh'
            }}
          />
        </Spin>

      </Layout>

      {/* ==== Payment details information model =====  */}

      {isModalOpen && (
        <PaymentDetails
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          initialData={initialData}
        />
      )}

      {/* ===== Bank details information model =====  */}
      <BankDetails
        isModalOpen={bankDetailsModal}
        setIsModalOpen={setBankDetailsModal}
        bankinformation={bankInformation}
      />
    </>
  )
}

export default Payment
