import { Modal, Collapse } from "antd";
import { useEffect, useState} from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import "./vendorQuestionModal.scss" ; 

const {Panel} = Collapse ; 

const VendorQuestionModal = ({isModalOpen, setIsModalOpen, vendorQuestionData}) => {

    const [question, setQuestion] = useState([]) ; 

    const SetQuestionData = () => {

        let temp = [] ; 

        // Question1 data
        temp.push({
            'question': "Have you ever been convicted of a felony or misdemeanor? If yes, please provide details of the offense and the outcome.",
            'answer': vendorQuestionData[1] == "2"?"No":"Yes"
        })

        // Question2 data
        temp.push({
            'question': "Do you have any ongoing legal issues or pending criminal cases that may affect your ability to provide services?", 
            'answer': vendorQuestionData[2] == "2"?"No":"Yes"
        })
        
        // Question3 data 
        temp.push({
            'question': "Have you been previously banned or removed from any other service platform due to violations of their policies or unethical behavior?", 
            'answer': vendorQuestionData[3] == "2"?"No":"Yes"
        })
        
        // Question4 data 
        temp.push({
            'question': "Can you provide references or past clients who can vouch for the quality of your services and professionalism?", 
            'answer': vendorQuestionData[4] == "2"?"No": "Yes"
        })

        // Question5 data 
        temp.push({
            'question': "Are you licensed, certified, or registered to offer the specific services you plan to provide on our platform? If yes, please share relevant documentation?", 
            "answer": vendorQuestionData[5] == "2"?"No":"Yes"
        })

        setQuestion([...temp]) ; 

    }

    useEffect(() => {
        SetQuestionData() ; 
    }, []) ; 

    const onChange = (key) => {
        console.log(key);
    };


    return(
        <Modal
            title = "Vendor Security Questions"
            open = {isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer = {null}
            cancelButtonProps={{style: {display:"none"}}}
            style={{width: "60%"}}
            centered
            className = "citizencare-model vendor-question-modal"
            width={800}
        >

        <div style={{padding : "1rem"}}>

            <Collapse 
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                defaultActiveKey={[0]} 
                onChange={onChange} 
                style={{marginBottom: "1rem"}}
            >
                {question.map((element, index) => (
                    <Panel header={element?.question} key={index}>
                        <p>{element?.answer}</p>
                    </Panel>
                  
                ))}
            </Collapse>
        
        </div>


        </Modal>
    )
}

export default VendorQuestionModal ; 