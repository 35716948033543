import React, { useEffect } from "react";
import {Layout, message} from 'antd' ; 
import SlideBar from "../slidebar/slidebar";
import { APIHandler } from "../../handler/apiHandler";
import { useNavigate } from "react-router-dom";

export default function BasicLayout({children}){
    
    const navigation = useNavigate() ; 

    const [messageApi, contextHolder] = message.useMessage() ; 

    const AdminDetailsCheck = async () => {

        let adminToken = localStorage.getItem("accessToken") ; 

        if (adminToken == null){
            navigation("/") ; 
        
        }   else{
        
            let responseData = await APIHandler("POST", {}, "admin/adminDetail") ; 
            
            if (responseData === false){
                messageApi.warning("Failed to verify admin") ; 
            }   else if (!responseData?.status){
                navigation("/") ; 
            }

        }

    }

    useEffect(() => {
        AdminDetailsCheck() ; 
    }, []) ; 

    return(
        <>  
            {contextHolder} 

            <Layout style={{height: "100vh", overflowY:"hidden"}}>

                <SlideBar
                    messageApi = {messageApi}
                />
                {React.cloneElement(children, { messageApi })}

            </Layout>
        </>
    )
}