import React, { useEffect, useState } from "react";
import { Layout, Table, Breadcrumb, Button, Tooltip, Flex, Spin, Image, Popconfirm, Modal } from "antd";
import { VerifiedOutlined, FileImageOutlined, QuestionOutlined } from "@ant-design/icons";

import BreadcrumpComp from "../../component/breadcrumb";
import VendorDocumentModal from "../../component/vendor/vendorDocumentModel";
import VendorQuestionModal from "../../component/vendor/vendorQuestionModal";

import * as CitizenColorCode from "../../colorcode/colorcode";
import { APIHandler } from "../../handler/apiHandler";
import dateFormat from "dateformat";
import { SetPageTitle } from "../../handler/commonHandler";
import moment from "moment";

const PendingVendor = ({ messageApi }) => {

    const [loading, setLoading] = useState(false);
    const [vendorData, setVendorData] = useState([]); 
    const [verifiedLoading, setVerifiedLoading] = useState(false) ; 
    
    const [documentModel, setDocumentModel] = useState(false) ; 
    const [documentData, setDocumentData] = useState({}) ; 

    const [questionModal, setQuestionModal] = useState(false) ;  
    const [questionData, setQuestionData] = useState({}) ; 

    useEffect(() => {
        SetPageTitle("Pending Vendor") ; 
    }, []) ; 

    // **** Pending vendor account conformation handler **** // 
    const VendorAccountConfirm = async (vendorId) => {

        setVerifiedLoading(true) ; 

        let requestPayload = {
            "id": vendorId, 
            "proofRefVerified": true
        }; 

        let responseData = await APIHandler("POST", requestPayload, "admin/vendorAccountVerification") ; 
        
        setVerifiedLoading(false) ; 
        
        if (responseData === false){

            messageApi.warning("Network request failed"); 
       
        }   else if (responseData?.status === true){

            messageApi.success("Successfully verified vendor account") ; 
            FetchVendorData() ; 

        }   else {

            messageApi.warning(responseData?.message) ; 
        }
    }

    const TableAction = (record) => {
        return (

            <Flex justify="center" gap={"0.4rem"}>

                {/* ==== Document inforamtion model ====  */}

                <Tooltip
                    placement="bottom"
                    title = "Documents"
                    >
                    <Button
                        type="primary"
                        className="user-delete"
                        size = {"small"}
                        icon = {
                            <FileImageOutlined
                                style={{color: CitizenColorCode.PrimaryColor}}
                            />
                        }
                        onClick={() => {
                            setDocumentData(record?.record?.proofRef) ; 
                            setDocumentModel(true);
                        }}
                        >
                    </Button>
                </Tooltip>

                {/* ==== Vendor question information related data ====  */}
                <Tooltip
                    placement="bottom"
                    title = "Vendor Security Question"
                >
                    <Button
                        type="primary"
                        className="user-delete"
                        size = {"small"}
                        icon = {
                            <QuestionOutlined
                                style={{color: CitizenColorCode.PrimaryColor}}
                            />
                        }
                        onClick={() => {
                            setQuestionData(record?.record?.criminalQnA) ; 
                            setQuestionModal(true);
                        }}
                        style={{marginLeft: "0.50rem"}}
                        >
                    </Button>
                </Tooltip>

                {/* ==== Veirifed vendor conformation model ====  */}
                <Popconfirm
                    placement="topLeft"
                    title = "Verified Vendor"
                    description = "Are you sure you want to verified this vendor account ?"
                    okText = "Verify"
                    cancelText = "Cancel"
                    onConfirm={() => VendorAccountConfirm(record?.record?.id)}
                    okButtonProps={{
                        loading: verifiedLoading,
                    }}
                >
                    <Tooltip placement="bottom" title="Verified user">
                        <Button
                            type="primary"
                            shape="round"
                            icon={
                                <VerifiedOutlined
                                    style={{ color: CitizenColorCode.PrimaryColor }}
                                />
                            }
                            size={"small"}
                            className="user-delete"
                        />
                    </Tooltip>
                </Popconfirm>

            </Flex>
        );
    };

    const columns = [

        {
            title: "User image",
            dataIndex: "profile_img",
            render: (text, record) => (
                <Image className="user-profile-image" src={text} alt={record.name} width={56} height={56} />
            ),
            align: "center"
        },

        {
            title: "VendorId",
            dataIndex: "vendorId",
            key: "name",
            align: "center", 
            sorter : (a, b) => a.vendorId - b.vendorId
        },

        {
            title: "Full Name",
            dataIndex: "firstname",
            key: "name",
            align: "center", 
            sorter : (a, b) => a.firstname - b.firstname
        },

        {
            title: "Middle name",
            dataIndex: "middlename",
            align: "center", 
            sorter: (a,b) => a.middlename - b.middlename
        },

        {
            title: "Last name",
            dataIndex: "lastname",
            align: "center", 
            sorter: (a,b) => a.lastname - b.lastname
        },

        {
            title: "DOB",
            dataIndex: "dob",
            align: "center"
        },

        {
            title: "Mobile number",
            dataIndex: "mobileNumber",
            align: "center",
            sorter: (a,b) => a.mobileNumber - b.mobileNumber
        },

        {
            title: "Email address",
            dataIndex: "email",
            align: "center", 
            sorter: (a,b) => a.email - b.email
        },

        {
            title: "Address",
            dataIndex: "address1",
            align: "center"
        },

        {
            title: "Created at",
            dataIndex: "createdAt",
            render: (text, record) => (
                moment(text).format("MM-DD-YYYY HH:MM:SS")
            ),
            align: "center", 
            sorter: (a,b) => a.createdAt - b.createdAt
        },

        {
            title: "Updated at",
            dataIndex: "updatedAt",
            render: (text, record) => (
                moment(text).format("MM-DD-YYYY HH:MM:SS")
            ),
            align: "center", 
            sorter: (a,b) => a.updatedAt - b.updatedAt
        },

        {
            title: "Action",
            align: "center", 
            fixed: "right", 
            render: (text, record) => <TableAction record = {record}/>
        }
    ];

    // **** Fetch pending vendor data **** // 
    const FetchVendorData = async () => {

        setLoading(true);

        let responseData = await APIHandler("GET", {}, "admin/getPendingVerifyAccounts");

        setLoading(false);

        if (responseData === false) {

            messageApi.warning("Network request failed");

        } else if (responseData?.status === true) {

            setVendorData([...responseData?.data]);
        } else {
            messageApi.warning(responseData?.message);
        }
    }

    useEffect(() => {
        FetchVendorData()
    }, []);

    return (
        <>
            <Layout
                className="admin-panel-layout"
                style={{
                    maxHeight: "100vh",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingTop: "0.5rem",
                }}
            >

                <BreadcrumpComp
                    data={[
                        {"name": "Vendors"}, 
                        {"name": "Unverified vendors"}
                    ]}
                />

                <Spin spinning={loading}>

                    <Table

                        style={{ height: "100vh", marginTop: '0.8rem' }}
                        columns={columns}
                        dataSource={vendorData}
                        scroll={{
                            x: 1500,
                            y: "85vh",
                        }}
                    />

                </Spin>

            </Layout> 

            {documentModel && (
                <VendorDocumentModal
                    isModalOpen={documentModel}
                    setIsModalOpen={setDocumentModel}
                    documentData={documentData}
                />

            )}

            {questionModal && (
                <VendorQuestionModal
                    isModalOpen={questionModal}
                    setIsModalOpen={setQuestionModal}
                    vendorQuestionData={questionData}
                />
            )}
        
        </>
    );
};

export default PendingVendor;
