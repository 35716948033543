import { Modal, Table } from "antd";
import { useEffect, useState } from "react";

const BankDetails = ({isModalOpen, setIsModalOpen, bankinformation}) => {

    // Bank details information related column
    const [bankData, setBankData] = useState([]) ; 
    const columns = [
        {
            title: "Name", 
            dataIndex: "name"
        }, 
        {
            title: "Information", 
            dataIndex: "information"
        }
    ]; 

    useEffect(() => {
        let temp = [] ; 

        // Insert bank information 
        temp.push({
            name: "Bank name", 
            information: bankinformation?.bank_name
        })

        // Insert bank account number information 
        temp.push({
            name: "Account number", 
            information: bankinformation?.account_number
        })

        // Insert bank routing number information 
        temp.push({
            name: "Routing number", 
            information: bankinformation?.routingnumber
        })

        // Insert Bank address information 
        temp.push({
            name: "Bank address", 
            information: bankinformation?.address
        })

        // Bank zip code 
        temp.push({
            name: "Zipcode", 
            information: bankinformation?.zip_code
        }) ; 

        setBankData([...temp]) ; 
        
    }, [isModalOpen]) ; 
    return(
        <Modal
            title = "Bank details"
            open = {isModalOpen}
            centered
            onCancel={() => {setIsModalOpen(false)}}
            footer = {null}
        >   
            <Table
                style={{marginLeft: "0.60rem", marginRight: "0.60rem", marginTop: "1rem"}}
                columns={columns}
                dataSource={bankData}
                pagination = {false}
                className="bank-details-table"
            />

        </Modal>
    )
}

export default BankDetails ; 