import React from 'react';
import { Route, Routes,BrowserRouter as Router } from "react-router-dom"; 
import './App.css';

import NotFound from './pages/notfound/notfound';
import {routes} from './routes/router';
import BasicLayout from './component/common/BasicLayout';


// Login page
import Login from './pages/login';

function App() {
  return (    
    <div>
      <Router>
        <Routes>

          {/* Admin login  */}
          <Route path="/" element={<Login/>} />

          {/* Other route  */}
          {routes.map((element, index) => (
            <Route 
              key = {index} 
              path={element?.path} 
              element={
                <BasicLayout 
                  children={element?.component}>
                </BasicLayout>} />
          ))}

          {/* Not found page */}
          <Route path='*' element = {<NotFound/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
