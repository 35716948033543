import {
    Layout,
    Card,
    Button,
    Row,
    Col,
    Form,
    Modal,
    Input,
    Upload
} from "antd";
import React, { useEffect, useState } from 'react'
import BreadcrumpComp from "../../component/breadcrumb";
import "./notification.scss"
import TextArea from "antd/es/input/TextArea";
import { SetPageTitle } from "../../handler/commonHandler";

const Notification = ({ messageApi }) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [notificationName, setNotificationName] = useState('');
    const [notificationDetails, setNotificationDetails] = useState('');
    const [image, setImage] = useState(null);

    useEffect(() => {
        SetPageTitle("Notification") ;
    }, []) ; 

    const handleOk = () => {
        setModalVisible(false);
    };

    const handleCancel = () => {
        // If user cancels, close the modal
        setModalVisible(false);
    };

    const uploadProps = {
        beforeUpload: file => {
            setImage(file);
            return false; // Prevent upload
        }
    };

    return (
        <>
            <Layout
                className="admin-panel-layout"
                style={{
                    maxHeight: "100vh",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingTop: "0.5rem",
                }}
            >
                <BreadcrumpComp data={
                    [{ "name": "Notification" }]} 
                />

                <div>
                    {/* Send notification in Android  */}
                    <Card className="notification-card-layout">
                        <Row gutter={16} className="row">
                            <Col lg={3} md={24} sm={24} xs={24} className="notification-title">
                                <div className="notification-title-text">Android</div>
                            </Col>
                            <Col lg={16} md={24} sm={24} xs={24} className="notification-option-button">
                                <Button type="primary" className='send-notification-button'
                                onClick={() => setModalVisible(true)}>Send Notification to Customer</Button>
                                <Button type="primary" className='send-notification-button second-notification-option-button'
                                onClick={() => setModalVisible(true)}>Send Notification to Vendor</Button>
                            </Col>
                        </Row>
                    </Card>

                    {/* Send notification on IOS  */}
                    <Card className="notification-card-layout">
                        <Row gutter={16} className="row" >
                            <Col lg={3} md={24} sm={24} xs={24} className="notification-title">
                                <div className="notification-title-text">IOS</div>
                            </Col>
                            <Col lg={16} md={24} sm={24} xs={24} className="notification-option-button">
                                <Button type="primary" className='send-notification-button' onClick={() => setModalVisible(true)}>
                                    Send Notification to Customer
                                </Button>
                                <Button type="primary" className='send-notification-button second-notification-option-button' onClick={() => setModalVisible(true)}>
                                    Send Notification to Vendor
                                </Button>
                            </Col>
                        </Row>
                    </Card>

                </div>


                {/* Modal for entering notification details */}
                <Modal
                    title="Send Notification"
                    visible={modalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={"40%"}
                    className="citizencare-model send-notification-model"
                >
                    <Form layout="vertical"  className="category-create-layout">
                        <Form.Item label="Notification name" className="mt-3">
                            <Input value={notificationName} onChange={(e) => setNotificationName(e.target.value)} />
                        </Form.Item>
                        <Form.Item label="Notification details">
                            <TextArea value={notificationDetails} onChange={(e) => setNotificationDetails(e.target.value)} rows={6}/>
                        </Form.Item>
                        {/* <Form.Item label="Upload Image">
                            <Upload {...uploadProps} accept="image/*" maxCount={1}>
                                <Button>Select Image</Button>
                            </Upload>
                        </Form.Item> */}
                    </Form>
                </Modal>
            </Layout>

        </>
    )
}

export default Notification; 