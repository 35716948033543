import { Breadcrumb } from "antd"
import { useNavigate } from "react-router-dom"; 
import "./index.scss" ; 

const BreadcrumpComp = ({data}) => {
    const navigation = useNavigate() ; 

    return(
        <Breadcrumb>
            {data.map((element) => {
                return(
                    <Breadcrumb.Item href = {null} 
                        onClick={() => {navigation(element?.href)}}>
                        {element?.name}
                    </Breadcrumb.Item>
                )
            })}
        </Breadcrumb>    
    )
}

export default BreadcrumpComp ; 