import { Modal, Spin, Card, Flex, Image, Tag, Table } from 'antd'
import { useEffect, useState } from 'react'
import { BankOutlined } from '@ant-design/icons'
import './paymentDetails.scss'
import { render } from '@testing-library/react'

const PaymentDetails = ({ isModalOpen, setIsModalOpen, initialData }) => {
  const [confirmLoading, setConfirmLoading] = useState(false) ; 
  const [tableData, setTableData] = useState([]) ; 

  const columns = [
    {
      "title": "Id",
      dataIndex: "id"
    }, 
    {
      "title": "Service name", 
      dataIndex: "service_name", 
      render: (text, record) => (
        <Flex>
          <Image
            src = {record?.image?.image1}
            className='table-service-image'
          />
          <div className='table-service-name'>
            {record?.name}
          </div>
        </Flex>
      )
    }, 
    {
      "title": "Number of Service", 
      width: 130,
      dataIndex: "number_of_service"
    }, 
    {
      "title": "Total service amount", 
      width: 150,
      dataIndex: "total_amount", 
      render: (text, record) => (
        `$${text}`
      )
    },
    { 
      "title": "Convience fee", 
      width: 150,
      dataIndex: "total_amount", 
      render: (text, record) => (
        `$5`
      )
    }, 
    {
      "title": "Coupon discount", 
      dataIndex: "coupon_discount",
      render: (text, record) => (
        `$${text}`
      )
    }, 
    {
      "title": "Coupon type", 
      dataIndex: "coupon_type",
      render: (text, record) => (
        text == undefined?<>
          <Tag>Not applied coupon</Tag>
        </>:<>
          {text === "Admin"?<>
            <Tag color='#87d068'>Admin</Tag>
          </>:<>
            <Tag color='#108ee9'>Vendor</Tag>
          </>}
        </>
      )
    }, 
    {
      "title": "Tax", 
      dataIndex: "tax_amount", 
      width: 100,
      render: (text, record) => (
        text == NaN?"$0":`$${text}`        
      )

    }, 
    {
      "title": "Tip amount", 
      dataIndex: "tipamount", 
      width: 100, 
      render: (text,record) => (
        `$${text}`
      )
    }, 
    {
      "title": "Paid amount",
      dataIndex: "vendor_paid_amount", 
      render: (text, record) => (
        `$${text}`
      )

    }
  ] ; 

  const [totalPaidAmount, setTotalPaidAmount] = useState(0) ; 
  
  const SetPaymentData = () => {
    
    setConfirmLoading(true) ; 

    // Set Payment data 
    let temp = [] ; 
    setTotalPaidAmount(0) ; 
    
    initialData.map((element, index) => {

      // Calculation related data 
      let number_of_service = parseInt(element?.quantity) ; 
      let single_service_price = parseFloat(element?.bill_json?.single_service_cost).toFixed(2) ; 
      let convience_fee = 5 ; 
      let discount_amount = (element?.bill_json?.coupon_disocunt == "" || element?.bill_json?.coupon_disocunt == null)?0:parseFloat(element?.bill_json?.coupon_disocunt).toFixed(2) ; 
      let coupon_type = element?.coupon !== null?element?.coupon?.create_by:undefined ; 
      let tax_amount = (element?.bill_json?.tax_amount !== undefined || element?.bill_json?.tax_amount !== "NaN")?parseFloat(element?.bill_json?.tax_amount).toFixed(2): 0 ;
      if (tax_amount == "NaN"){
        tax_amount = 0 ; 
      } 

      // Calculate vendor paid amount 
      let vendorpaid_amount = parseFloat(number_of_service*single_service_price).toFixed(2) ; 
      vendorpaid_amount = (parseFloat(vendorpaid_amount) + parseFloat(convience_fee)).toFixed(2);
      if (coupon_type !== undefined && coupon_type !== "Admin") {
        vendorpaid_amount = (parseFloat(vendorpaid_amount) - parseFloat(discount_amount)).toFixed(2);
      } 
      vendorpaid_amount = (parseFloat(vendorpaid_amount) + parseFloat(tax_amount)).toFixed(2) ; 

      // 95% amount goes to Vendor 
      vendorpaid_amount = parseFloat(vendorpaid_amount*0.95).toFixed(2) ; 
  
      if (coupon_type === "Admin"){
        vendorpaid_amount = (parseFloat(vendorpaid_amount) + parseFloat(discount_amount)).toFixed(2) ; 
      }; 

      let tipamount = parseFloat(parseFloat(element?.tip).toFixed(2)*0.80).toFixed(2); 
      vendorpaid_amount = (parseFloat(vendorpaid_amount) + parseFloat(tipamount)).toFixed(2) ; 

      setTotalPaidAmount((prev) => (parseFloat(vendorpaid_amount) + parseFloat(prev)).toFixed(2));

      temp.push({
        "id": parseInt(index) + 1, 
        "number_of_service":number_of_service,
        "total_amount": parseFloat(number_of_service*single_service_price).toFixed(2), 
        "coupon_discount": discount_amount,  
        "coupon_type": coupon_type, 
        "tax_amount": tax_amount,  
        "vendor_paid_amount": vendorpaid_amount, 
        "tipamount": tipamount, 
        ...element
      })
    })
    
    setConfirmLoading(false) ; 
    setTableData([...temp]) ; 
  }

  useEffect(()=> {
    SetPaymentData() ; 
  }, [initialData]) ; 

  return (
    <Modal
      title={`Payment Details | Paid amount $${totalPaidAmount}`}
      visible = {true}
      className='citizencare-model payment-details-modal'
      onCancel={() => setIsModalOpen(false)}
      footer={null}
      width={"80%"}
      centered
    >
      <Spin spinning={confirmLoading}>
        <Table
          columns={columns}
          dataSource={tableData}
          style={{marginLeft: "0.4rem", marginRight: "0.4rem"}}
          className='payment-details-table'
        />
      </Spin>
    </Modal>
  )
}

export default PaymentDetails
