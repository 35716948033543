import React, { useEffect, useState } from "react";
import {
  PieChartOutlined,
  UserOutlined, 
  NotificationOutlined,
  LogoutOutlined, 
  HistoryOutlined, 
  UsergroupAddOutlined, 
  DollarOutlined, 
  BarcodeOutlined, 
  TransactionOutlined,
  FundOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./slidebar.scss" ; 
import { Layout, Menu, Typography , Popconfirm } from "antd";
import { APIHandler } from "../../handler/apiHandler";

const {Sider } = Layout;



const SlideBar = ({messageApi}) => {
    
    const items = [
        { title: "Category", key: "1", icon: <PieChartOutlined /> },
        { title: "Customers", key: "2", icon: <UsergroupAddOutlined /> },
        { title: "Vendors", key: "3", icon: <UserOutlined/> },
        { title: "Peding Vendor", key: "4", icon: <HistoryOutlined/> },
        { title: "Pending Pay", key: "5", icon: <DollarOutlined/> },
        { title: "Coupon", key: "6", icon: <BarcodeOutlined/> },
        { title: "Notification", key: "7", icon: <NotificationOutlined /> },
        { title: "Transactions", key: "8", icon: <TransactionOutlined /> },
        { title: "Refunds", key: "9", icon: <FundOutlined /> },
    ];

    const navigate = useNavigate() ; 

    const [collapsed, setCollapsed] = useState(false);
    const [selected, setSelected] = useState(null) ; 

    const HandleItemClick = (key) => {
        setSelected(key) ; 

        if (key === "1"){
            navigate("/category") ; 

        }   else if (key === "2"){
            navigate("/customers") ; 
        
        }   else if (key === "3"){
            navigate("/vendor") ; 

        }   else if (key === "5"){
            navigate("/pendingPay") ; 
        
        }   else if (key === "4"){
            navigate("/pendingVendor") ; 
        
        }   else if (key === "6"){
            navigate("/coupon") ; 
        
        }   else if (key === "7"){
            navigate("/notification") ; 
        
        }else if (key === "8"){
            navigate("/transaction") ; 
        
        }   else if (key === "9"){
            navigate("/refunds") ; 
        }
    }

    useEffect(() => {
        
        let currentLocation = window.location.pathname ; 

        if (currentLocation == "/category"){
            setSelected("1") ; 

        }   else if (currentLocation == "/customers"){
            setSelected("2") ; 
        
        }   else if (currentLocation == "/vendor"){
            setSelected("3") ; 
        
        }   else if (currentLocation == "/pendingPay"){
            setSelected("5") ; 
        
        }   else if (currentLocation == "/pendingVendor"){
            setSelected("4") ;  

        }   else if (currentLocation == "/coupon"){
            setSelected("6") ; 
        
        }   else if (currentLocation == "/notification"){
            setSelected("7") ; 
        
        }   else if (currentLocation == "/transaction"){
            setSelected("8") ; 
        
        }   else if (currentLocation == "/refunds"){
            setSelected("9") ; 
        }
    }, [window.location.pathname]) ; 

    // **** User logout option handler *** // 

    const UserLogoutOptionHandler = async () => {

        
        let responseData = await APIHandler("POST", {}, "admin/logout") ; 
        
        if (responseData === false){
            messageApi.warning("Network request failed") ; 
            
        }   else if (responseData?.status){
            localStorage.removeItem("accessToken") ; 
            messageApi.success("Logout successfully") ; 
            navigate("/") ; 

        }   else {
            messageApi.warning(responseData?.message) ; 
        }
    }


    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            theme="light"
        >
            
            {/* Navigation bar title  */}

            {!collapsed ?<>
            
                <Typography.Title editable = {false} level={4} 
                    className="slidebar-title">
                    CetizenCare
                </Typography.Title>
            </>:<>
                
                <Typography.Title editable = {false} level={4} 
                    className="slidebar-title">
                    C
                </Typography.Title>
            </> }


            {/* Navigation menu option   */}
            
            <Layout style={{marginTop: "1.5rem"}}>
            
                {selected !== null && (
                    
                    <Menu
                        theme="light"
                        defaultSelectedKeys={[selected]}
                        mode="inline"
                        onClick={(item) => HandleItemClick(item.key)}>

                        {items.map((item) => (
                            <Menu.Item key={item.key}>
                                {item.icon}
                                <span>{item.title}</span>
                            </Menu.Item>
                        ))}
                        <Menu.Item key={"logout"}>
                            <Popconfirm
                                title = "Logout"
                                description = "Are you sure you want to logout?"
                                onConfirm={UserLogoutOptionHandler}
                                >
                                <LogoutOutlined/>
                                <span>Logout</span>
                            </Popconfirm>
                        </Menu.Item>
                    </Menu>
                )}
            
            </Layout>

        </Sider>
    );
};
export default SlideBar;
