import React from "react";
import { Layout, Button, Image, Flex, Tooltip, Table, Modal, Spin, Form, Upload, Input } from "antd";
import { APIHandler } from "../../handler/apiHandler";
import { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons"; 
import UploadImageHandler from "../../handler/imageUpload";

const CreateSubCategoryComp = ({
    isModalOpen, 
    setIsModalOpen,
    editModal, 
    messageApi, 
    categoryId, 
    fetchSubCategory
}) => {

    const [createCategoryForm] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false) ; 

    const props = {
        action: "//jsonplaceholder.typicode.com/posts/",
        listType: "picture",
    };
    

    // Create sub category hadler 
    const handleSubmit = async(values) => {

        setConfirmLoading(true) ; 

        let UploadImaegURL = await UploadImageHandler(values?.subcategoryImage) ; 

        let requestPayload = {
            name  : values?.subcategoryname, 
            category_id: categoryId, 
            category_image: UploadImaegURL
        }; 

        let responseData = await APIHandler("POST", requestPayload, "admin/createSubCategory"); 

        setConfirmLoading(false) ; 
        setIsModalOpen(false) ; 
        createCategoryForm.resetFields() ; 

        if (responseData === false){

        messageApi.warning("Network request failed") ; 
        
        } else if (responseData['status'] === true){

            messageApi.success("Create subcategory successfully") ; 
            fetchSubCategory() ; 

        } else {

        messageApi.warning(responseData?.message) ; 
        
        }
    }


    return(
        <>

            
            <Modal
                title={editModal?"Edit Subcategory":"Create Subcategory"}
                open={isModalOpen}
                onOk={() => {
                    createCategoryForm.submit();
                }}
                onCancel={() => {
                    setIsModalOpen(false);
                }}
                width={"40%"}
                className="citizencare-model"
                confirmLoading={confirmLoading} 
                centered
            >
                <Spin spinning={confirmLoading}>
                    <Form
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        form={createCategoryForm}
                        onFinish={handleSubmit}
                        autoComplete={"off"}
                        className="category-create-layout"
                    >
                        {/* ==== Sub category name input ==== */}

                        <Form.Item
                            name="subcategoryname" // Adjust the name to a single word without spaces
                            label="Subcategory name"
                            rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: "Please, Enter subcategory name",
                            },
                            ]}
                        >
                            <Input
                            style={{
                                marginBottom: "0.5rem",
                                padding: "0.7rem",
                                fontSize: "1rem",
                            }}
                            placeholder="Please, Enter subcategory name"
                            />
                        </Form.Item>

                        {/* ==== Upload Category image ==== */}

                        <Form.Item
                            name="subcategoryImage"
                            label="Subcategory image"
                            style={{ marginTop: "14px" }}
                            rules={[
                            {
                                required: true,
                                message: "Please upload a subcategory image",
                            },
                            ]}
                        >
                            <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Subcategory image</Button>
                            </Upload>
                        </Form.Item>

                    </Form>

                </Spin>
            </Modal>
        </>
    )
}

export default CreateSubCategoryComp ;  