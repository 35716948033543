import { Modal, Carousel, Image } from "antd";
import { useEffect, useState } from "react";
import "./vendorDocumentModel.scss" ; 

const VendorDocumentModal = ({isModalOpen, setIsModalOpen, documentData}) => {

    const setDocumentDataInformation = () => {
        let temp = [] ; 

        Object.keys(documentData).forEach((key) => {
            temp.push({
                'document_name': key, 
                'document_image': documentData[key]
            })
        })
    }

    useEffect(() => {
        setDocumentDataInformation() ; 
    }, []); 

    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    return(
        <Modal
            title = "Vendor documents"
            open = {isModalOpen}
            className="citizencare-model"
            onCancel={() => setIsModalOpen(false)}
            footer = {null}
            cancelButtonProps={{style: {display:"none"}}}
            style={{width: "60%"}}
            centered
        >   

            <div style={{padding: "1rem"}}>

                <Carousel afterChange={onChange}>
                    
                    {/* Driving license front image  */}
                    
                    {documentData?.drivingFrontImage !== undefined && (
                        <div>
                            <Image 
                                src= {documentData?.drivingFrontImage}
                                alt="" 
                                srcset="" 
                                className="vendor-document-image"
                                />
                            <h3 className="vendor-document-title">Driving License Front image</h3>
                        </div>
                    )}

                    {/* Driving license back image  */}

                    {documentData?.drivingBackImage !== undefined && (
                        <div>
                            <Image 
                                src= {documentData?.drivingBackImage}
                                alt="" 
                                srcset="" 
                                className="vendor-document-image"
                                />
                            <h3 className="vendor-document-title">Driving License Back image</h3>
                        </div>
                    )}

                    {/* State license image  */}

                    {documentData?.stateLicenseImage !== undefined && (
                        <div>
                            <Image 
                                src= {documentData?.stateLicenseImage}
                                alt="" 
                                srcset="" 
                                className="vendor-document-image"
                                />
                            <h3 className="vendor-document-title">State License image</h3>
                        </div>
                    )}

                    {/* State number information image  */}
                    <div>
                        <Image 
                            src= {documentData?.stateNumberImage}
                            alt="" 
                            srcset="" 
                            className="vendor-document-image"
                            />
                        <h3 className="vendor-document-title">State Number information image</h3>
                    </div>

                    {/* User profile front image */}
                    <div>
                        <Image 
                            src= {documentData?.userprofileFrontImage}
                            alt="" 
                            srcset="" 
                            className="vendor-document-image"
                            />
                        <h3 className="vendor-document-title">User profile front image</h3>
                    </div>

                    {/* User profile side view image  */}
                    <div>
                        <Image 
                            src= {documentData?.userprofileSideImage}
                            alt="" 
                            srcset="" 
                            className="vendor-document-image"
                            />
                        <h3 className="vendor-document-title">User profile side view image</h3>
                    </div>
                </Carousel>

            </div>


        </Modal>
    )
}

export default VendorDocumentModal ; 