import React, { useEffect, useState } from 'react'
import {
  Layout,
  Table,
  Button,
  Tooltip,
  Flex,
  Spin,
  Popconfirm
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons'
import dayjs from 'dayjs'

import BreadcrumpComp from '../../component/breadcrumb'
import * as CitizenColorCode from '../../colorcode/colorcode'
import CreateCouponComp from '../../component/coupon/createCouponComp'
import { APIHandler } from '../../handler/apiHandler'
import moment from 'moment'
import { SetPageTitle } from '../../handler/commonHandler'

const Coupon = ({ messageApi }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [couponData, setCouponData] = useState([])
  const [refetch, setRefetch] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [initialValue, setInitialValue] = useState({})
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    SetPageTitle("Coupon") ; 
  }, []) ; 

  // **** Delete coupon option handler **** // 
  const deleteActionHandler = async (record) => {
    setIsLoading(true)
    let deleteRes = await APIHandler("PATCH", {}, `admin/coupons/delete/${record?.record?.id}`);

    if (deleteRes === false) {
      messageApi.warning('Network request failed')
      setIsLoading(false)
    } else if (deleteRes?.status === true) {
      messageApi.success('Delete coupon successfully')
      FetchAllCoupon()
    } else {
      messageApi.warning(deleteRes?.message)
      setIsLoading(false)
    }
  }

  const TableAction = record => {
    const data = record?.record
    return (
      <Flex>

        {/* ==== Edit coupon option icon ====  */}

        <Tooltip
          placement='bottom'
          title='Edit coupon'
          className='category-action-option'
        >
          <Button
            type='primary'
            shape='round'
            icon={
              <EditOutlined style={{ color: CitizenColorCode.PrimaryColor }} />
            }
            onClick={() => {
              setIsEdit(true)
              let couponImage = data?.image
              const parts = couponImage.split('/')
              const imageName = parts[parts.length - 1]

              let imageData = { uid: '-1', name: imageName, status: 'done', url: couponImage }
              setInitialValue({
                couponName: data?.offer_name,
                offerType: data?.offer_type,
                offerdescription: data?.description,
                offerCode: data?.offer_code,
                offerpercentage: data?.percentage,
                Offerdate: [dayjs(data?.start_date), dayjs(data?.end_date)],
                offermaxAmount: data?.maxAmount,
                offermaxdiscount: data?.maxDiscount,
                couponImage: [imageName],
                id: data?.id
              })

              setFileList([imageData])

              setIsModalOpen(true)
            }}
            size={'small'}
            className='user-delete'
          />
        </Tooltip>

        {/* ==== Delete coupon option icon ====  */}
        <Tooltip
          placement='bottom'
          title='Delete coupon'
          className='category-action-option'
        >
          <Popconfirm 
            title = "Delete coupon"
            description = "Are you sure you want to delete coupon ?"
            onConfirm={() => deleteActionHandler(record)}
            >
            <Button
              type='primary'
              shape='round'
              icon={<DeleteOutlined style={{ color: CitizenColorCode.PrimaryColor }} />}
              size={'small'}
              className='user-delete'
            />
          </Popconfirm>

        </Tooltip>

      </Flex>
    )
  }

  const columnns = [
    {
      title: 'Index',
      dataIndex: 'key'
    },

    {
      title: 'Coupon name',
      dataIndex: 'offer_name'
    },

    {
      title: 'Coupon code',
      dataIndex: 'offer_code'
    },

    {
      title: 'Offer percentage',
      dataIndex: 'percentage'
    },

    {
      title: 'Offer start date',
      dataIndex: 'start_date'
    },

    {
      title: 'Offer end date',
      dataIndex: 'end_date'
    },

    {
      title: 'Action',
      key: 'operation',
      fixed: 'right',
      width: 30,
      render: (text, record) => <TableAction record={record} />
    }
  ]
 
  // **** Reterive coupon list data **** // 
  const FetchAllCoupon = async () => {
    setIsLoading(true)

    let responseData = await APIHandler('GET', {}, 'admin/coupons/get')

    setIsLoading(false)

    if (responseData === false) {
      messageApi.warning('Network request failed')
    } else if (responseData['status'] === true) {
      setCouponData(
        responseData.data.map((element, index) => ({
          ...element,
          key: parseInt(index) + 1,
          offer_name: element?.offer_name,
          offer_code: element?.offer_code,
          percentage: element?.percentage,
          start_date: moment(element?.start_date).format('YYYY-MM-DD'),
          end_date: moment(element?.end_date).format('YYYY-MM-DD'),
          description: element?.description,
          maxAmount: element?.maxAmount,
          maxDiscount: element?.maxDiscount,
          offer_type: element?.offer_type,
          image: element?.image,
          id: element?.id
        }))
      )
      setRefetch(false)
    } else {
      messageApi.warning(responseData?.message)
    }
  }

  useEffect(() => {
    if (refetch === true) {
      FetchAllCoupon()
    }
  }, [refetch]) ; 

  return (
    <>
      {/* {contextHolder} */}
      <Layout
        className='admin-panel-layout'
        style={{
          maxHeight: '100vh',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingTop: '0.5rem'
        }}
      >
        <BreadcrumpComp data={[{"name": 'Offer coupon'}]} />

        <div className='table-data'>

          <div className='table-option'>
          
            <Button
              type='primary'
              className='create-category-button'
              onClick={() => {
                setInitialValue({})
                setIsModalOpen(true)
                setFileList([])
              }}
            >
              Create new coupon
            </Button>
          
          </div>
        
        </div>

        <Spin spinning={isLoading}>
          <Table 
            dataSource={couponData} 
            columns={columnns} 
          />
        </Spin>
      </Layout>

      {isModalOpen && (
        <CreateCouponComp
          isModalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          messageApi={messageApi}
          setRefetch={setRefetch}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          initialValue={initialValue}
          fileList={fileList}
        />
      )}
    </>
  )
}

export default Coupon
