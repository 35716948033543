import { Modal, Form, ColorPicker, Spin, Upload, notification, Input, Button, message } from "antd";
import UploadImageHandler from "../../handler/imageUpload";
import { UploadOutlined } from "@ant-design/icons";
import { APIHandler } from "../../handler/apiHandler";
import { useEffect, useState } from "react";

const CreateCategoryComp = ({ isModalOpen,
    setIsModalOpen, 
    FetchAllCategory, 
    editModal, 
    categoryId, 
    messageApi
}) => {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [categoryDataLoading, setCategoryDataLoading] = useState(false) ; 

    const [hexColorCode, setHexColorCode] = useState(null) ; 
    const [createCategoryForm] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [frominitialValue, setFromInitialValue] = useState({ }) ; 

    const props = {
        action: "//jsonplaceholder.typicode.com/posts/",
        listType: "picture",
        defaultFileList : [...fileList]
    };


    // *** Create category request handler **** // 
    const CreateCategoryHandler = async (values) => {

        setConfirmLoading(true);
    
        let S3UploadURL = await UploadImageHandler(values?.categoryImage);
        
        let hexColorCode = document.getElementsByClassName("ant-color-picker-trigger-text")[0];
        hexColorCode = hexColorCode.innerText;

        let requestPayload = {
            "name": values?.categoryName,
            "category_img": S3UploadURL,
            "category_background_color": hexColorCode
        };
    
        let responseData = await APIHandler("POST", requestPayload, "admin/createCategory");
    
        setConfirmLoading(false);
        setIsModalOpen(false);
    
        if (responseData === false) {
            
            messageApi.warning("Network request failed") ; 
    
        } else if (responseData['status'] === true) {
            
            messageApi.success("Create category successfully") ; 
            createCategoryForm.resetFields() ; 
            FetchAllCategory();
    
        } else {
    
            messageApi.warning(responseData?.messgae) ; 
        }
    }

    const handleSubmit = async (values) => {

        if (editModal === false){
            CreateCategoryHandler(values) ; 
        }   else {

        }
    };

    // **** Fetch particular category information **** // 
    const FetchParticularCategoryInformation = async () => {

        createCategoryForm.resetFields() ; 
        setConfirmLoading(true) ; 
        setCategoryDataLoading(true) ; 

        let responseData = await APIHandler("GET", {}, `admin/getCategory/${categoryId}`) ; 

        setConfirmLoading(false) ;
        
        if (responseData === false){
            
            messageApi.warning("Network request failed") ; 
            
        }   else if (responseData?.status === true){
            
            let categoryImage = responseData?.data?.category_img ; 
            const parts = categoryImage.split('/');
            const imageName = parts[parts.length - 1];
            
            let data = { uid: '-1', name: imageName, status: 'done', url: categoryImage} ;
            setFileList([data]) ;   
            
            setFromInitialValue({
                categoryName: responseData?.data?.name, 
                backgroundColor : responseData?.data?.category_background_color
            }) ; 
            
            setHexColorCode(responseData?.data?.category_background_color) ; 
            setCategoryDataLoading(false)

        }   else {

            messageApi.warning(responseData?.message) ; 
        }
    }

    useEffect(() => {

        if (editModal){
            FetchParticularCategoryInformation() ; 
        }   else {
            setCategoryDataLoading(false) ; 
        }
    }, [editModal]) ;  

    return (
        <>
            <Modal
                title= {editModal ==true?"Edit category":"Create category"}
                open={isModalOpen}
                okText = {editModal === true?"Edit":"Create"}
                onOk={() => { createCategoryForm.submit(); }}
                onCancel={() => { setIsModalOpen(false); }}
                width={"40%"}
                className="citizencare-model"
                confirmLoading={confirmLoading}
                centered
            >
                <Spin spinning={confirmLoading}>

                    {!categoryDataLoading && (

                        <Form
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            form={createCategoryForm}
                            initialValues = {frominitialValue}
                            onFinish={handleSubmit}
                            autoComplete={"off"}
                            className="category-create-layout"
                        >
                            {/* ==== Category name input ==== */}

                            <Form.Item
                                name="categoryName" // Adjust the name to a single word without spaces
                                label="Category name"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please, Enter category name",
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        marginBottom: "0.5rem",
                                        padding: "0.7rem",
                                        fontSize: "1rem",
                                    }}
                                    placeholder="Please, Enter category name"
                                />
                            </Form.Item>

                            {/* ==== Upload Category image ==== */}

                            <Form.Item
                                name="categoryImage"
                                label="Category image"
                                style={{ marginTop: "14px" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please upload a category image",
                                    },
                                ]}
                            >
                                <Upload {...props} >
                                    <Button icon={<UploadOutlined />}>Category image</Button>
                                </Upload>    
                            </Form.Item>

                            {/* ==== Category background color picker ==== */}

                            <Form.Item
                                name="backgroundColor"
                                label="Background color"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a background color",
                                    },
                                ]}
                            >
                                <ColorPicker 
                                    size="large" 
                                    showText 
                                    format="hex" 
                                    value={hexColorCode} 
                                    onChange={setHexColorCode}
                                />
                            </Form.Item>

                        </Form>

                    )}


                </Spin>

            </Modal>
        </>
    )
};

export default CreateCategoryComp; 